<template>
    <section class="op-bitacora-semana">
        <div class="d-flex justify-content-center">
            <div class="contenedor-semanas d-inline-flex justify-content-center flex-wrap border py-1 br-4">
                <div v-for="(data,i) in 52" :key="i" class="div-semana text-center f-12">
                    {{ i + 1 }}
                </div>
            </div>
        </div>
        <!-- ejemplo -->
        <div class="d-flex justify-content-center mt-4">
            <div class="contenedor-semanas d-inline-flex justify-content-center flex-wrap">
                <div class="div-semana text-center f-12">
                    <div class="wh-24 rounded-circle d-middle-center"> 1 </div>
                </div>
                <div class="div-semana text-center f-12">
                    <div class="wh-24 active rounded-circle d-middle-center"> 2 </div>
                </div>
                <div class="div-semana text-center f-12">
                    <div class="wh-24 some rounded-circle d-middle-center"> 3 </div>
                </div>
            </div>
        </div>
        <div class="row mt-4 justify-content-center">
            <div class="col-12">
                <FullCalendar :options="calendarOptions" class="fullcalendar-styles" />
            </div>
        </div>
        <!-- partials -->
        <modal-detalle-tarea ref="modalDetalleTarea" />
    </section>
</template>

<script>
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
export default {
    components:{
        modalDetalleTarea: () => import('./partials/modalDetalleTarea.vue'),
    },
    data(){
        return{
            calendarOptions: {
                plugins: [ dayGridPlugin, interactionPlugin, timeGridPlugin ],
                initialView: 'timeGridWeek',
                locale: 'es',
                selectable: true,
                height: '500px',
                // timeFormat: 'H:mm',
                // axisFormat: 'H(:mm)',
                slotLabelFormat:{
                    hour: 'numeric',
                    minute: '2-digit',
                    omitZeroMinute: true,
                    meridiem: 'short'
                },
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: ''
                },
                allDayText: 'Día',
                buttonText:{
                    today: 'Hoy',
                    week: 'Semana',
                    month: 'Mes',
                },
                dateClick: function(info) {
                    alert('clicked ' + info.dateStr);
                },
                eventClick: this.handleClick,
                dayMaxEvents: true,
                allDaySlot: false,
                events: [
                    {   
                        id: 0,
                        title: 'Salida',
                        start: '2021-11-05T10:30:00',
                        end: '2021-11-05T11:30:00',
                        className: 'pendiente',
                    },
                    {
                        id:1,
                        title: 'Almuerzo',
                        start: '2021-11-03T13:30:00',
                        end: '2021-11-03T13:30:00',
                        className: 'reservado'
                    },
                    {
                        title: 'Halloween',
                        start: '2021-10-31T00:00:00',
                        end: '2021-10-31T24:00:00',
                        className: 'pendiente'
                    },
                    {
                        title: 'All Day Event',
                        start: '2021-11-04T10:30:00',
                        end: '2021-11-04T11:30:00',
                        className: 'reservado'
                    },
                    {
                        title: 'All Day Event 2',
                        start: '2021-11-02T10:30:00',
                        end: '2021-11-02T11:30:00',
                        className: 'actual'
                    },
                ],
                
            },
        }
    },
    methods:{
        handleClick(){
            this.$refs.modalDetalleTarea.toggle()
        }
    }
}
</script>

<style lang="scss" scoped>
.op-bitacora-semana{
    .contenedor-semanas{
         max-width: 1161px;
         width: 1161px;
         min-width: 1161px;
        .div-semana{
            width: 43px;
            min-width: 43px;
            max-width: 43px;
            .active{
                background: var(--color-general);
                color: white;
            }
            .some{
                background: var(--color-db);

            }
        }
    }
}

</style>